import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Session } from 'src/model/session.model';
import { Attendance } from 'src/model/attendance.model';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
// config
import { PAGINATION_LINK } from '../config-global';

interface SessionState {
  isLoading: boolean;
  sessionsData: Session[];
  sessionsType: string[];
  error: {};
  detailsError: {};
  sessionDetailsData: Session;
  attendance: Attendance;
  attendanceError: {};
  totalData: number;
  upcomingCount: number;
  inProgressCount: number;
  completedCount: number;
  cancelledCount: number;
  hostedSessionsCount: number;
  attendedSessionsCount: number;
  missedSessionsCount: number;
  joiningDate:string;
}

const initialState: SessionState = {
  isLoading: false,
  sessionsData: [],
  sessionsType: [],
  error: {},
  detailsError: {},
  sessionDetailsData: {},
  attendance: {},
  attendanceError: {},
  totalData: 0,
  upcomingCount: 0,
  inProgressCount: 0,
  completedCount: 0,
  cancelledCount: 0,
  hostedSessionsCount:0,
  attendedSessionsCount:0,
  missedSessionsCount:0,
  joiningDate:''
};

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSessionsList(state, action) {
      
      state.sessionsData           = action.payload.sessions;
      state.totalData              = action.payload.total_count;
      state.hostedSessionsCount  = action.payload.hosted_sessions_count;
      state.attendedSessionsCount  = action.payload.attended_sessions_count;
      state.missedSessionsCount  = action.payload.missed_sessions_count;
      state.sessionsType           = action.payload.session_types;
      state.upcomingCount          = action.payload.upcoming_count;
      state.inProgressCount        = action.payload.in_progress_count;
      state.completedCount         = action.payload.completed_count;
      state.cancelledCount         = action.payload.cancelled_count;
      state.joiningDate            = action.payload.joining_date;

    },
    setSessionsError(state, action) {
      state.error = action.payload;
    },
    setSessionsDetails(state, action) {
      state.sessionDetailsData = action.payload.session;
    },
    setSessionDetailsError(state, action) {
      state.detailsError = action.payload;
    },
    setAttendanceError(state, action) {
      state.attendanceError = action.payload;
    },
  },
});

export const {
  setLoading,
  setSessionsList,
  setSessionsDetails,
  setSessionsError,
  setSessionDetailsError,
  setAttendanceError,
} = sessionSlice.actions;
export default sessionSlice.reducer;

export const fetchSessionList = createAsyncThunk(
  'session/fetchSessionList',
  async ({ employeeId = "", type = "", teamId = "", searchTerm = "", sort = "", sortKey = "", page = 0, limit = '', startDate=null, endDate=null, sessionType="All", sessionStatus="all"}: {limit?:string | number; startDate?:Date | null; endDate?:Date | null; employeeId?: string; type?: string; teamId?: string; searchTerm?: string, sort?: string, sortKey?: string, page?: number, sessionType?: string, sessionStatus?: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params: Record<string, Date | string> = {
        skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
        limit: `${limit}`,
      };
  
      if (employeeId) params.session_by = employeeId;
      if (teamId) params.vt_team_uuid = teamId;
      if (searchTerm) params.search_term = searchTerm;
      if (sort) params.sort = sort;
      if (sortKey) params.sort_key = sortKey;
      if (type)  params.session_type = type === "all" ? "" : type;
      if (startDate) params.start_date = new Date(startDate);
      if (endDate) params.end_date = new Date(endDate);
      if (sessionType) params.session_type_filter = sessionType;
      if (sessionStatus) params.status = sessionStatus;
      const response =
        await axios.get(Api.sessions + Api.list, { params });
      dispatch(setSessionsList(response?.data?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const fetchAttendedSessionList = createAsyncThunk(
  'session/fetchSessionList',
  async ({ employeeId = "", type = "", teamId = "", searchTerm = "", sort = "", sortKey = "", page = 0, formattedStartDate=null, formattedEndDate=null, sessionType="All"}: {formattedStartDate?:string | null; formattedEndDate?:string | null; employeeId?: string; type?: string; teamId?: string; searchTerm?: string, sort?: string, sortKey?: string, page?: number, sessionType?: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params: Record<string, Date | string> = {
        skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
        limit: `${PAGINATION_LINK.PER_PAGE_COUNT}`,
      };
  
      if (employeeId) params.user_uuid = employeeId;
      if (teamId) params.vt_team_uuid = teamId;
      if (searchTerm) params.search_term = searchTerm;
      if (sort) params.sort = sort;
      if (sortKey) params.sort_key = sortKey;
      if (type)  params.session_type = type === "all" ? "" : type;
      if (formattedStartDate) params.start_date = formattedStartDate;
      if (formattedEndDate) params.end_date = formattedEndDate;
      if (sessionType) params.session_type_filter = sessionType;
      const response =
        await axios.get(Api.sessions + Api.employeeSessions, { params });
      dispatch(setSessionsList(response?.data?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const editSession = createAsyncThunk(
  'session/editSession',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.edit, data);
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const addSession = createAsyncThunk(
  'session/addSession',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.add, data);
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error?.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const deleteSession = createAsyncThunk(
  'session/deleteSession',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.delete, data);
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const sessionDetail = createAsyncThunk(
  'session/detailSession',
  async ({
    uuid = '',
    sort = '',
    sortKey = ''
  }: { uuid?: string; sort?: string, sortKey?: string },
  { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params: Record<string, string> = {};
      if (sort) params.sort = sort;
      if (sortKey) params.sort_key = sortKey;
      const response = await axios.get(`${Api.sessions + Api.details}/${uuid}`,{ params });
      dispatch(setSessionsDetails(response?.data?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.status !== 200) {
        //   // Custom message handled
        dispatch(setSessionDetailsError(error.message));
      } else {
        //   // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionDetailsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const addSessionAttendance = createAsyncThunk(
  'session/addAttendance',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.addAttendance, data);
      dispatch(sessionDetail({uuid: data?.session_uuid}));
      return { success: true }; // Return success flag
    } catch (error) {
      dispatch(setAttendanceError(error?.message));
      throw error; // Throw the error to indicate failure
    }
  }
);

export const importSessionAttendance = createAsyncThunk(
  'session/importAttendance',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.importAttendance, data);
      dispatch(sessionDetail({uuid: data?.get("session_uuid")}));
      return { success: true }; // Return success flag
    } catch (error) {
      dispatch(setAttendanceError(error?.message));
      throw error; // Throw the error to indicate failure
    }
  }
);

export const deleteSessionAttendance = createAsyncThunk(
  'session/deleteAttendance',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.deleteAttendance, data);
      dispatch(sessionDetail({uuid: data?.session_uuid}));
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const deleteSessionAttachment = createAsyncThunk(
  'session/deleteSessionAttachment',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.sessions + Api.deleteDocument, data);
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setSessionsError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setSessionsError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);