import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Employee } from 'src/model/employee.model';
import { Team } from 'src/model/teams.model';
import { Role } from 'src/model/roles.model';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { fetchUserProfile } from './userSlice';
// config
import { PAGINATION_LINK } from '../config-global';

interface EmployeeState {
  isLoading: boolean;
  employeesData: Employee[];
  rolesData: Role[];
  teamsData: Team[];
  locationData: any[];
  skillData: any[];
  totalData: 0;
  billableEmployees: 0;
  benchedEmployees: 0;
  allocatedEmployees: 0;
  error: {};
  profiePic: string;
  mentors:any;
}

interface FetchEmployeeListParams {
  teamId?: string;
  skill?: any;
  page?: number;
  search?: string;
  allocation?: string;
  status?: string;
  limit?: string|number;
  sort?:string,
  sortKey?:string,
  trainee?:string,
  mentors?:any,
  subRole?:string,
  sessionId?:string,
}

const initialState: EmployeeState = {
  isLoading: false,
  employeesData: [],
  rolesData: [],
  teamsData: [],
  locationData: [],
  skillData: [],
  totalData: 0,
  billableEmployees: 0,
  benchedEmployees: 0,
  allocatedEmployees: 0,
  error: {},
  profiePic: '',
  mentors:[],
};

export const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setEmployeesList(state, action) {
      const {
        employees = [],
        qb_roles = [],
        vt_teams = [],
        locations = [],
        all_skills = [],
        total_count = 0,
        billable_count = 0,
        allocated_count = 0,
        benched_count = 0,
        mentors = []
      } = action.payload

      state.employeesData = employees;
      state.rolesData = qb_roles;
      state.teamsData = vt_teams;
      state.locationData = locations;
      state.skillData = all_skills;
      state.totalData = total_count;
      state.billableEmployees = billable_count;
      state.allocatedEmployees = allocated_count;
      state.benchedEmployees = benched_count;
      state.mentors = mentors;
    },
    setEmployeeProfilePicture(state, action) {
      state.profiePic = action.payload.profilePic;
    },
    setEmployeesError(state, action) {
      state.error = Object.values(action.payload).flat();
    },
  },
});

export const { setLoading, setEmployeesList, setEmployeeProfilePicture, setEmployeesError } =
  employeeSlice.actions;
export default employeeSlice.reducer;

export const fetchEmployeeList = createAsyncThunk(
  'employees/fetchEmployeeList',
  async (
    params: FetchEmployeeListParams,
    { dispatch }
  ) => {
    try {
      dispatch(setLoading(true));

      const queryParams: Record<string, string|number> = {
        vt_team_uuid: params.teamId || '',
        skills: params.skill || '',
        search_term: params.search || '',
        allocation: params.allocation || '',
        status: params.status || '',
        subrole: params.subRole || '',
        skip: params.page ? `${params.page * PAGINATION_LINK.PER_PAGE_COUNT}` : '',
        limit: params.limit || '',
        sort: params.sort || 'asc',
        sort_key: params.sortKey || 'name',
        trainee: params.trainee?`${params.trainee}` : '',
        session_id:params.sessionId || '',
      };

      const response = await axios.get(Api.employeeList, { params: queryParams });
      dispatch(setEmployeesList(response?.data?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setEmployeesError(error?.data));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setEmployeesError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const editEmployee = createAsyncThunk(
  'employees/editEmployee',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.employees + Api.edit, data);
      // dispatch(fetchEmployeeList({}));
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setEmployeesError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setEmployeesError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const addEmployee = createAsyncThunk(
  'employees/addEmployee',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.employees + Api.add, data);
      // dispatch(fetchEmployeeList({}));
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setEmployeesError(error?.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setEmployeesError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  'employees/deleteEmployee',
  async (data: any, { dispatch }) => {
    try {
      await axios.post(Api.employees + Api.delete, data);
      // dispatch(fetchEmployeeList({}));
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setEmployeesError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setEmployeesError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const setProfilePic = createAsyncThunk(
  'employees/setProfilePic',
  async (data: any, { dispatch }) => {
    try {
      const response = await axios.post(Api.employees + Api.profilePic, data);
      dispatch(setEmployeeProfilePicture(response?.data?.data));
      dispatch(fetchUserProfile());
      return { success: true }; // Return success flag
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setEmployeesError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setEmployeesError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);
